<template>
  <div class="flex flex-col lg:flex-row lg:space-x-4">
    <!-- SideBar -->
    <Sidebar
      :treatmentCategories="treatmentCategories"
      :setTreatmentOptions="setTreatmentOptions"
      @updateTreatmentOptions="setTreatmentOptions = $event"
    />
    <!-- End SideBar -->
    <vs-card class="w-full lg:w-9/12">
      <h5 class="text-green-dark font-bold my-3 mx-2">
        {{ subMenuText() }}
      </h5>
      <vs-tabs v-model="active" :key="forceRender" class="insightsTabs">
        <vs-tab label="By Clinic">
          <!-- Number of Treatments per Treatment Area -->
          <template v-if="isSubToxinTreatmentStatus.treatmentArea || isSubFillerTreatmentStatus.treatmentArea || isSubAllTreatmentStatus.treatmentArea">
            <TreatmentsPerTreatmentArea :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event"/>
          </template>

          <!-- Average per Treatment -->
          <template v-if="isSubToxinTreatmentStatus.byArea || isSubFillerTreatmentStatus.byArea || isSubAllTreatmentStatus.byArea">
            <AverageTreatmentByArea :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>
        </vs-tab>
        
        <vs-tab label="By Treatment Area">
          <!-- Number of Treatments per Treatment Area -->
          <template v-if="isSubToxinTreatmentStatus.treatmentArea || isSubFillerTreatmentStatus.treatmentArea || isSubAllTreatmentStatus.treatmentArea">
            <TreatmentsPerTreatmentArea isArea :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>

          <!-- Average per Treatment -->
          <template v-if="isSubToxinTreatmentStatus.byArea || isSubFillerTreatmentStatus.byArea || isSubAllTreatmentStatus.byArea">
            <AverageTreatmentByArea isArea :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>
        </vs-tab>

        <vs-tab label="By Patient Age">
          <!-- Number of Treatments per Treatment Area -->
          <template v-if="isSubToxinTreatmentStatus.treatmentArea || isSubFillerTreatmentStatus.treatmentArea || isSubAllTreatmentStatus.treatmentArea">
            <TreatmentsPerTreatmentArea isAge :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>

          <!-- Average per Treatment -->
          <template v-if="isSubToxinTreatmentStatus.byArea || isSubFillerTreatmentStatus.byArea || isSubAllTreatmentStatus.byArea">
            <AverageTreatmentByArea :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>
        </vs-tab>
        
        <vs-tab label="By Treatment Area">
          <!-- Number of Treatments per Treatment Area -->
          <template v-if="isSubToxinTreatmentStatus.treatmentArea || isSubFillerTreatmentStatus.treatmentArea || isSubAllTreatmentStatus.treatmentArea">
            <TreatmentsPerTreatmentArea isArea :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>

          <!-- Average per Treatment -->
          <template v-if="isSubToxinTreatmentStatus.byArea || isSubFillerTreatmentStatus.byArea || isSubAllTreatmentStatus.byArea">
            <AverageTreatmentByArea isArea :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>
        </vs-tab>

        <vs-tab label="By Patient Age">
            <!-- Number of Treatments per Treatment Area -->
            <template v-if="isSubToxinTreatmentStatus.treatmentArea || isSubFillerTreatmentStatus.treatmentArea || isSubAllTreatmentStatus.treatmentArea">
              <TreatmentsPerTreatmentArea isAge :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
            </template>

            <!-- Average per Treatment -->
            <template v-if="isSubToxinTreatmentStatus.byArea || isSubFillerTreatmentStatus.byArea || isSubAllTreatmentStatus.byArea">
              <AverageTreatmentByArea isAge :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
            </template>
        </vs-tab>

        <vs-tab label="By Nurse">
          <!-- Number of Treatments per Treatment Area -->
          <template v-if="isSubToxinTreatmentStatus.treatmentArea || isSubFillerTreatmentStatus.treatmentArea || isSubAllTreatmentStatus.treatmentArea">
            <TreatmentsPerTreatmentArea isNurse :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>

          <!-- Average per Treatment -->
          <template v-if="isSubToxinTreatmentStatus.byArea || isSubFillerTreatmentStatus.byArea || isSubAllTreatmentStatus.byArea">
            <AverageTreatmentByArea isNurse :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>
        </vs-tab>

        <vs-tab label="By Week">
          <!-- Number of Treatments per Treatment Area -->
          <template v-if="isSubToxinTreatmentStatus.treatmentArea || isSubFillerTreatmentStatus.treatmentArea || isSubAllTreatmentStatus.treatmentArea">
            <TreatmentsPerWeek :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>

          <!-- Average per Treatment -->
          <template v-if="isSubToxinTreatmentStatus.byArea || isSubFillerTreatmentStatus.byArea || isSubAllTreatmentStatus.byArea">
            <TreatmentsPerWeek :isAllClinicsProps="isAllClinics" @updateIsAllClinics="isAllClinics = $event" />
          </template>
        </vs-tab>

      </vs-tabs>
      <!-- <router-view
        :setTreatmentOptions="setTreatmentOptions"
        @updateTreatmentOptions="setTreatmentOptions = $event"
      /> -->
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Sidebar from "./Sidebar.vue";
import TreatmentsPerTreatmentArea from './TreatmentsPerTreatmentArea.vue';
import AverageTreatmentByArea from './AverageTreatmentByArea.vue'
import TreatmentsPerWeek from "./TreatmentsPerWeek.vue";

export default {
  components: {
    Sidebar,
    TreatmentsPerTreatmentArea,
    AverageTreatmentByArea,
    TreatmentsPerWeek
  },
  props: {},
  data() {
    return {
      urlType: "",
      setTreatmentOptions: [],
      chartData: {},
      treatmentCategories: [],
      active: 0,
      forceRender: 1,
      nurseOptions: {},
      clinicOptions: {},
      byAgeOptions:{},
      byAreaOptions:{},
      byWeekOptions:{},
      isAllClinics: false,
    };
  },
  methods: {
    ...mapActions("analytics", ["fetchAnalyticsFilters"]),
    goToInsights() {
      this.$router.push({ name: `${this.urlType}Insights` });
    },
    redirectToTab(url){
      if (url && url !== this.$route.name){ 
        this.$router.push({ name: url })
      }
    },
    subMenuText() {
      let toxinText = null;
      let fillerText = null;
      let allText = null;
      Object.keys(this.isSubToxinTreatmentStatus).forEach((e) => {
        if (this.isSubToxinTreatmentStatus[e]) {
          toxinText = e;
        }
      });
      Object.keys(this.isSubFillerTreatmentStatus).forEach((e) => {
        if (this.isSubFillerTreatmentStatus[e]) {
          fillerText = e;
        }
      });
      Object.keys(this.isSubAllTreatmentStatus).forEach((e) => {
        if (this.isSubAllTreatmentStatus[e]) {
          allText = e;
        }
      });
      if (toxinText) {
        return this.setTreatmentOptions[2].subMenu.find(
          (e) => e.urlParams === toxinText
        ).text;
      } else if (fillerText) {
        return this.setTreatmentOptions[1].subMenu.find(
          (e) => e.urlParams === fillerText
        ).text;
      } else {
        return this.setTreatmentOptions[0].subMenu.find(
          (e) => e.urlParams === allText
        ).text;
      }
    },
    getTabUrl(data) {
      let dataProps = this.setTreatmentOptions.filter((e) => 
        e.isActive && e.subMenu.length)[0].subMenu.filter((side) => 
        side.isActive);
      if (this.$route.name.includes("Filler")) {
        if (this.$route.name !== data.fillerName) {
          if (dataProps[0].urlParams === 'byArea' && data.fillerName === 'SuperAdminAverageTreatmentsPerTreatmentAreaFiller'){
            return 'SuperAdminAverageTreatmentByAreaFiller'
          }
          return data.fillerName;
        }
      } else if (this.$route.name.includes("All")) {
        if (this.$route.name !== data.allName) {
          if (dataProps[0].urlParams === 'byArea' && data.toxinName === 'SuperAdminAverageTreatmentsPerTreatmentAreaAll'){
            return 'SuperAdminAverageTreatmentByAreaAll'
          }
          return data.allName;
        }
      } else {
        if (this.$route.name !== data.toxinName) {
          if (dataProps[0].urlParams === 'byArea' && data.toxinName === 'SuperAdminAverageTreatmentsPerTreatmentAreaToxin'){
            return 'SuperAdminAverageTreatmentByAreaToxin'
          }
          return data.toxinName;
        }
      }
    },
    getFilters() {
      let payload = {}
      const org_id = [localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)]
      const brands = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).brands;
      if (!["SuperAdmin", "Supplier"].includes(this.urlType)) payload = {org_id}
      if (!this.isAllClinics && this.urlType === "Supplier" && brands.length) payload = {brand_id: brands}
      this.$vs.loading();
      this.fetchAnalyticsFilters(payload).then(() => this.$vs.loading.close()
      ).catch((err) => {
        console.error("##ERROR: ", err.message)
        this.$vs.loading.close();
      });
    },
    setUrlParams() {
      this.setTreatmentOptions = [
        {
          text: "All Treatments",
          urlParams: "all",
          isActive: this.$route.path.includes('all') ? true : false,
          subMenu: [
            {
              text: "Number of Treatments per Treatment Area (All)",
              urlParams: "treatmentArea",
              isActive: this.$route.name === `${this.urlType}AverageTreatmentsPerTreatmentAreaAll` ? true : false,
              url: `${this.urlType}AverageTreatmentsPerTreatmentAreaAll`,
            },
            {
              text: "Average Dose (mL) per Treatment (All)",
              urlParams: "byArea",
              isActive: this.$route.name === `${this.urlType}AverageTreatmentByAreaAll` ? true : false,
              url: `${this.urlType}AverageTreatmentByAreaAll`,
            },
          ]
        },
        {
          text: "Filler",
          urlParams: "filler",
          isActive: this.$route.path.includes('filler') ? true : false,
          subMenu: [
            {
              text: "Number of Treatments per Treatment Area (Filler)",
              urlParams: "treatmentArea",
              isActive: this.$route.name === `${this.urlType}AverageTreatmentsPerTreatmentAreaFiller` ? true : false,
              url: `${this.urlType}AverageTreatmentsPerTreatmentAreaFiller`,
            },
            {
              text: "Average Dose (mL) per Treatment (Filler)",
              urlParams: "byArea",
              isActive: this.$route.name === `${this.urlType}AverageTreatmentByAreaFiller` ? true : false,
              url: `${this.urlType}AverageTreatmentByAreaFiller`,
            },
          ],
        },
        {
          text: "Toxin",
          urlParams: "toxin",
          isActive: this.$route.path.includes('toxin') ? true : false,
          subMenu: [
            {
              text: "Number of Treatments per Treatment Area (Toxin)",
              urlParams: "treatmentArea",
              isActive: this.$route.name === `${this.urlType}AverageTreatmentsPerTreatmentAreaToxin` ? true : false,
              url: `${this.urlType}AverageTreatmentsPerTreatmentAreaToxin`,
            },
            {
              text: "Average Units per Treatment (Toxin)",
              urlParams: "byArea",
              isActive: this.$route.name === `${this.urlType}AverageTreatmentByArea` ? true : false,
              url: `${this.urlType}AverageTreatmentByAreaToxin`,
            },
          ],
        },
      ]
      this.byAreaOptions = {
        text: "Average Treatment per Appointment by Area",
        urlParams: "byArea",
        isActive: false,
        tabName: "By Treatment Area",
        allName: `${this.urlType}AverageTreatmentByAreaAll`,
        fillerName: `${this.urlType}AverageTreatmentByAreaFiller`,
        toxinName: `${this.urlType}AverageTreatmentByAreaToxin`,
      },
       this.byAgeOptions = {
        text: "Average Treatment per Appointment by Age",
        urlParams: "byAge",
        isActive: false,
        tabName: "By Patient Age",
        allName: `${this.urlType}AverageTreatmentByAgeAll`,
        fillerName: `${this.urlType}AverageTreatmentByAgeFiller`,
        toxinName: `${this.urlType}AverageTreatmentByAgeToxin`,
      },
      this.nurseOptions = {
        text: "Treatment Area By Nurse",
        urlParams: "byNurse",
        isActive: false,
        tabName: "By Nurse",
        allName: `${this.urlType}InsightsTreatmentByNurseAll`,
        fillerName: `${this.urlType}InsightsTreatmentByNurseFiller`,
        toxinName: `${this.urlType}InsightsTreatmentByNurseToxin`,
      }
      this.clinicOptions = {
        text: "Number of Treatments per Treatment Area (Toxin)",
        urlParams: "treatmentArea",
        isActive: false,
        tabName: "By Clinic",
        allName: `${this.urlType}AverageTreatmentsPerTreatmentAreaAll`,
        fillerName: `${this.urlType}AverageTreatmentsPerTreatmentAreaFiller`,
        toxinName: `${this.urlType}AverageTreatmentsPerTreatmentAreaToxin`,
      },
      this.byWeekOptions = {
        text: "Number of Treatments by Week",
        urlParams: "byWeek",
        isActive: false,
        tabName: "By Week",
        allName: `${this.urlType}TreatmentsPerWeekAll`,
        fillerName: `${this.urlType}TreatmentsPerWeekFiller`,
        toxinName: `${this.urlType}TreatmentsPerWeekToxin`,
      }
    },
    tabSetToDefault(){
      this.active = 0;
    }
  },
  computed: {
    isSingleTreatment() {
      return this.setTreatmentOptions.some(
        (e) => e.isActive && !e.subMenu.length
      );
    },
    isSubToxinTreatmentStatus() {
      let subToxin = this.setTreatmentOptions.find(
        (item) => item.urlParams === "toxin"
      );
      let options = {
        treatmentArea: false,
        byArea: false,
        byAge: false,
        byGenderAndAge: false,
        byNurse: false,
        byWeek: false,
      };
      options.treatmentArea = subToxin.subMenu[0].isActive;
      options.byArea = subToxin.subMenu[1].isActive;
      this.tabSetToDefault()
      return options;
    },
    isSubFillerTreatmentStatus() {
      let subFiller = this.setTreatmentOptions.find(
        (item) => item.urlParams === "filler"
      );
      let options = {
        treatmentArea: false,
        byArea: false,
        byAge: false,
        byGenderAndAge: false,
        byNurse: false,
        byWeek: false,
      };
      options.treatmentArea = subFiller.subMenu[0].isActive;
      options.byArea = subFiller.subMenu[1].isActive;
      this.tabSetToDefault()
      return options;
    }, 
    isSubAllTreatmentStatus() {
      let subAll = this.setTreatmentOptions.find(
        (item) => item.urlParams === "all"
      );
      let options = {
        treatmentArea: false,
        byArea: false,
        byAge: false,
        byGenderAndAge: false,
        byNurse: false,
        byWeek: false,
      };
      options.treatmentArea = subAll.subMenu[0].isActive;
      options.byArea = subAll.subMenu[1].isActive;
      this.tabSetToDefault()
      return options;
    },
  },
  watch: {
    $route() {
      this.forceRender += 1;
    },
    isAllClinics: {
      handler(){
        this.getFilters();
      },
    }
  },
  created() {
    let userType = JSON.parse(
    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if(userType && userType.userType === "superAdmin"){
      this.urlType = "SuperAdmin";
    }else if(userType && userType.organizations[0].role.toUpperCase() === 
    "supplier".toUpperCase()){
      this.urlType = "Supplier";
    }else{
      this.urlType = "OrgOwner";
    }
    this.getFilters();
    this.setUrlParams();
  },
  destroyed() {
    this.hideMarkerWidget();
  }
};
</script>

<style>
  .insightsTabs .vs-tabs--ul {
    overflow: auto
  } 
  .insightsTabs .vs-tabs--li button.vs-tabs--btn {
    padding: .5rem 1rem;
  }
</style>
