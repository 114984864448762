<template>
  <vs-row vs-justify="flex-end">
    <vs-button color="primary" class="mb-2" type="flat" style="color: #82868B" @click="resetFilters">Reset
      Filters</vs-button>
    <vs-row class="filter-container">
      <!-- States -->
      <div class="flex items-center justify-end">
        <!-- <div class="text-xs">Select States</div> -->
        <vs-select
          v-model="selected.state"
          placeholder="Select States"
          label="Select States"
          multiple
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.value"
            v-for="(item, index) in optionsStates"
          />
        </vs-select>
        <vs-icon v-if="selected.state.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.state = []"></vs-icon>
        <!-- <Select v-model="selected.state" show-search mode="multiple" placeholder="Select States" style="width: 200px;"
          class="m-1" :options="optionsStates" :autoClearSearchValue="false" :filter-option="filterOption"
          :allowClear="true">
        </Select> -->
      </div>

      <!-- Postcodes -->
      <div class="flex items-center justify-end">
        <!-- <div class="text-xs">Select Postcodes</div> -->
        <vs-select
          v-model="selected.postcode"
          placeholder="Select Postcodes"
          label="Select Postcodes"
          autocomplete
          multiple
          @input-change="handleSearchPostcode"
          class="m-1"
          :disabled="!selected.state.length"
          @blur="blur('postcode')"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsPostCodes"
          />
        </vs-select>
        <vs-icon v-if="selected.postcode.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.postcode = []"></vs-icon>
        <!-- <Select v-model="selected.postcode" mode="multiple" class="m-1" placeholder="Select Postcodes"
          style="width: 200px;" @search="handleSearchPostcode" @blur="blur('postcode')" :autoClearSearchValue="false"
          :loading="postcode.isLoading" :disabled="!selected.state.length" :options="optionsPostCodes"
          :filterOption="false" :allowClear="true">
          <div slot="dropdownRender" slot-scope="menu">
            <v-nodes v-if="!isFetching" :vnodes="menu" />
            <div v-else id="fetch-loading" style="height: 100px" class="vs-con-loading__container"></div>
          </div>
        </Select> -->
      </div>

      <!-- Suburb -->
      <div class="flex items-center justify-end">
        <!-- <div class="text-xs">Select Suburbs</div> -->
        <vs-select
          v-model="selected.suburb"
          placeholder="Select Suburbs"
          label="Select Suburbs"
          autocomplete
          multiple
          @input-change="handleSearchSuburb"
          class="m-1"
          :disabled="!selected.state.length"
          @blur="blur('suburb')"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsSuburbs"
          />
        </vs-select>
        <vs-icon v-if="selected.suburb.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.suburb = []"></vs-icon>
        <!-- <Select v-model="selected.suburb" mode="multiple" placeholder="Select Suburbs" style="width: 200px;" class="m-1"
          @search="handleSearchSuburb" @blur="blur('suburb')" :autoClearSearchValue="false" :loading="suburb.isLoading"
          :disabled="!selected.state.length" :options="optionsSuburbs" :filterOption="false" :allowClear="true">
          <div slot="dropdownRender" slot-scope="menu">
            <v-nodes v-if="!isFetching" :vnodes="menu" />
            <div v-else id="fetch-loading" style="height: 100px" class="vs-con-loading__container"></div>
          </div>
        </Select> -->
      </div>

      <!-- Areas -->
      <div v-if="['percent-of-treatment'].includes(page)" class="flex items-center justify-end">
        <!-- <div class="text-xs">Select Area</div> -->
        <vs-select
          v-model="selected.area"
          placeholder="Select Area"
          label="Select Area"
          autocomplete
          multiple
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsArea"
          />
        </vs-select>
        <vs-icon v-if="selected.area.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.area = []"></vs-icon>
        <!-- <Select v-model="selected.area" mode="multiple" placeholder="Select Area" style="width: 200px;" class="m-1"
          @search="handleSearchArea" @blur="blur('areas')" :autoClearSearchValue="false" :options="optionsArea"
          :filterOption="false" :allowClear="true">
          <div slot="dropdownRender" slot-scope="menu">
            <v-nodes v-if="!isFetching" :vnodes="menu" />
            <div v-else id="fetch-loading" style="height: 100px" class="vs-con-loading__container"></div>
          </div>
        </Select> -->
      </div>

      <!-- Clinics -->
      <div class="flex items-center justify-end">
        <!-- <div class="text-xs">Select Clinics</div> -->
        <vs-select
          v-model="selected.clinic"
          placeholder="Select Clinics"
          label="Select Clinics"
          autocomplete
          multiple
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsClinics"
          />
        </vs-select>
        <vs-icon v-if="selected.clinic.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.clinic = []"></vs-icon>
        <!-- <Select v-model="selected.clinic" mode="multiple" placeholder="Select Clinics" style="width: 200px;" class="m-1"
          @search="handleSearchClinic" @blur="blur('clinics')" :autoClearSearchValue="false" :options="optionsClinics"
          :maxTagCount="1" :filterOption="false" :maxTagTextLength="7" :allowClear="true">
          <div slot="dropdownRender" slot-scope="menu">
            <v-nodes v-if="!isFetching" :vnodes="menu" />
            <div v-else id="fetch-loading" style="height: 100px" class="vs-con-loading__container"></div>
          </div>
        </Select> -->
      </div>

      <!-- Frequency -->
      <!-- <div v-if="!['clinic-insights'].includes(page)"> -->
        <!-- <div class="text-xs">Select Frequency</div> -->
        <!-- <vs-select
          v-model="selected.frequency"
          placeholder="Select Frequency"
          label="Select Frequency"
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsFrequency"
          />
        </vs-select> -->
        <!-- <Select v-model="selected.frequency" placeholder="Select Frequency" style="width: 200px;" class="m-1"
          :options="optionsFrequency" :filter-option="filterOption">
        </Select> -->
      <!-- </div> -->

      <!-- Products -->
      <div
        class="flex items-center justify-end"
        v-if="!['single-vs-multiple-treatment', 'treatment-areas-by-brand', 'percent-of-treatment', 'brand-ml', 'brand-unit', 'clinic-insights'].includes(page)">
        <!-- <div class="text-xs">Select Products</div> -->
        <vs-select
          v-model="selected.product"
          placeholder="Select Products"
          label="Select Products"
          multiple
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsProducts"
          />
        </vs-select>
        <vs-icon v-if="selected.product.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.product = []"></vs-icon>
        <!-- <Select v-model="selected.product" mode="multiple" placeholder="Select Products" style="width: 200px;"
          class="m-1" @search="handleSearchProduct" @blur="blur('products')" :autoClearSearchValue="false"
          :options="optionsProducts" :allowClear="true" :filterOption="false">
          <div slot="dropdownRender" slot-scope="menu">
            <v-nodes v-if="!isFetching" :vnodes="menu" />
            <div v-else id="fetch-loading" style="height: 100px" class="vs-con-loading__container"></div>
          </div>
        </Select> -->
      </div>

      <!-- Brands -->
      <div
        class="flex items-center justify-end"
        v-if="!['single-vs-multiple-treatment', 'treatment-areas-by-brand', 'percent-of-treatment', 'brand-ml', 'brand-unit', 'clinic-insights'].includes(page)">
        <!-- <div class="text-xs">Select Brands</div> -->
        <vs-select
          v-model="selected.brand"
          placeholder="Select Brands"
          label="Select Brands"
          multiple
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsBrands"
          />
        </vs-select>
        <vs-icon v-if="selected.brand.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.brand = []"></vs-icon>
        <!-- <Select v-model="selected.brand" mode="multiple" placeholder="Select Brands" style="width: 200px;" class="m-1"
          @search="handleSearchBrand" @blur="blur('brands')" :autoClearSearchValue="false" :options="optionsBrands"
          :allowClear="true" :filterOption="false">
          <div slot="dropdownRender" slot-scope="menu">
            <v-nodes v-if="!isFetching" :vnodes="menu" />
            <div v-else id="fetch-loading" style="height: 100px" class="vs-con-loading__container"></div>
          </div>
        </Select> -->
      </div>

      <!-- Quintile -->
      <div v-if="['single-vs-multiple-treatment'].includes(page)">
        <!-- <div class="text-xs">Select Quintile</div> -->
        <vs-select
          v-model="selected.quintile"
          placeholder="Select Quintile"
          label="Select Quintile"
          autocomplete
          class="m-1"
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsQuintile"
          />
        </vs-select>
        <!-- <Select v-model="selected.quintile" placeholder="Select Quintile" style="width: 200px;" class="m-1"
          :options="optionsQuintile" :filter-option="filterOption" :allowClear="true">
        </Select> -->
      </div>

      <!-- Age -->
      <div
        class="flex items-center justify-end"
        v-if="['treatment-areas-by-brand', 'brand-ml', 'brand-unit'].includes(page)">
        <!-- <div class="text-xs">Select Age</div> -->
        <vs-select
          v-model="selected.age"
          placeholder="Select Age"
          label="Select Age"
          autocomplete
          class="m-1"
          multiple
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsAge"
          />
        </vs-select>
        <vs-icon v-if="selected.age.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.age = []"></vs-icon>
        <!-- <Select v-model="selected.age" mode="multiple" placeholder="Select Age" style="width: 200px;" class="m-1"
          :filter-option="filterOption" :options="optionsAge" :allowClear="true">
        </Select> -->
      </div>

      <!-- Search Nurse -->
      <div class="flex items-center justify-end" v-if="isOrgLoggedIn">
        <!-- <div class="text-xs">Search Nurse</div> -->
        <vs-select
          v-model="selected.nurse_id"
          placeholder="Search Nurse"
          label="Search Nurse"
          autocomplete
          class="m-1"
          multiple
        >
          <vs-select-item 
            :key="index"
            :value="item.value"
            :text="item.label"
            v-for="(item, index) in optionsNurse"
          />
        </vs-select>
        <vs-icon v-if="selected.nurse_id.length" icon="close" color="red" bg="white" class="removeSelectedFilters" @click="selected.nurse_id = []"></vs-icon>
        <!-- <Select v-model="selected.nurse_id" mode="multiple" placeholder="Search nurse" style="width: 200px;" class="m-1"
          @search="handleSearchNurse" @blur="blur('nurses')" :autoClearSearchValue="false" :options="optionsNurse"
          :allowClear="true" :filterOption="false">
          <div slot="dropdownRender" slot-scope="menu">
            <v-nodes v-if="!isFetching" :vnodes="menu" />
            <div v-else id="fetch-loading" style="height: 100px" class="vs-con-loading__container"></div>
          </div>
        </Select> -->
      </div>
    </vs-row>
  </vs-row>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { compact } from "lodash"

export default {
  components: {
  },
  props: {
    selected: {
      type: Object,
    },
    page: {
      type: String,
      default: "number-of-treatment-per-treatment-area"
    }
  },
  computed: {
    ...mapGetters("analytics", ["DEFAULT_STATE_FILTER"]),
    ...mapState("analytics", ["filters"]),
    optionsPostCodes() {
      return this.filters.postcodes.map(postcodes => (
        { value: postcodes.value, label: postcodes.text }
      ))
    },
    optionsSuburbs() {
      return this.filters.suburbs.map(suburbs => (
        { value: suburbs.value, label: suburbs.text }
      ))
    },
    optionsBrands() {
      return this.filters.brands.map(brands => (
        { value: brands.value, label: brands.text }
      ))
    },
    optionsProducts() {
      return this.filters.products.map(products => (
        { value: products.value, label: products.text }
      ))
    },
    optionsStates() {
      return this.filters.states.map(states => (
        { value: states.value, label: states.text }
      ))
    },
    optionsClinics() {
      return this.filters.clinics.map(clinics => (
        { value: clinics.value, label: clinics.text }
      ))
    },
    optionsArea() {
      return this.filters.area.map(area => (
        { value: area.value, label: area.text }
      ))
    },
    optionsQuintile() {
      return this.filters.quintile.map(quintile => (
        { value: quintile.value, label: quintile.text }
      ))
    },
    optionsAge() {
      return this.filters.age.map(age => (
        { value: age.value, label: age.text }
      ))
    },
    optionsFrequency() {
      return this.filters.frequency.map(freq => (
        { value: freq.value, label: freq.text }
      ))
    },
    optionsNurse() {
      return this.filters.nurses.map(nurse => (
        { value: nurse.value, label: nurse.text }
      ))
    },
    isOrgLoggedIn() {
      return !!localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`) &&
        [
          'average-treatment-by-area-filler', 'average-treatment-by-age-filler',
          'average-treatment-by-gender-and-age-filler', 'average-treatment-by-area-toxin',
          'average-treatment-by-age-toxin', 'average-treatment-by-gender-and-age-toxin',
          'treatment-areas-per-treatment-area-filler-nurse', 'treatment-areas-per-treatment-area-toxin-nurse'
        ].includes(this.page)
    }
  },
  data() {
    return {
      selectedData: {},
      postcode: {
        isLoading: false,
      },
      suburb: {
        isLoading: false,
      },
      timer: null,
      isFetching: false,
      triggerFilter: ''
    }
  },
  methods: {
    ...mapActions("analytics", [
      "fetchFilterByState", "fetchAnalyticsFiltersSearch",
      "fetchAnalyticsFiltersSearchClinic", "fetchClinicState"
    ]),
    ...mapMutations("analytics", ["MUTATE_FILTERS"]),
    resetFilters() {
      this.selected.brand = [];
      this.selected.postcode = [];
      this.selected.suburb = [];
      this.selected.product = [];
      this.selected.state = [];
      this.selected.clinic = [];
      this.selected.frequency = 30;
      this.selected.quintile = [0];
      this.selected.age = [];
      this.selected.area = [];
      this.selected.nurse_id = [];
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearchArea(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          await this.fetchAnalyticsFiltersSearch({
            type: 'areas',
            search: input.target.value,
          })
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchClinic(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          const params = {
            type: 'clinics',
            search: input.target.value,
            states: this.selectedData.state
          }
          const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
          if (org_id) params['org_id'] = org_id

          await this.fetchAnalyticsFiltersSearch(params)
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchBrand(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          await this.fetchAnalyticsFiltersSearch({
            type: 'brands',
            search: input.target.value,
          })
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchProduct(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          await this.fetchAnalyticsFiltersSearch({
            type: 'products',
            search: input.target.value,
          })
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchNurse(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          const params = {
            type: 'nurses',
            search: input.target.value,
          }
          const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
          if (org_id) params['org_id'] = org_id

          await this.fetchAnalyticsFiltersSearch(params)
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchPostcode(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          await this.fetchFilterByState({
            type: 'search',
            params: {
              search: input.target.value,
              postCode: true,
              prereq_value: this.selectedData.state
            }
          })
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    handleSearchSuburb(input) {
      this.isFetching = true
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async () => {
        // this.showLoading('#fetch-loading')
        try {
          await this.fetchFilterByState({
            type: 'search',
            params: {
              search: input.target.value,
              suburb: true,
              prereq_value: this.selectedData.postcode,
              prereq_value_2: this.selectedData.state
            }
          })
        } catch (error) {
          // silence is golden
        }
        // this.isFetching = false
        // this.closeLoading('#fetch-loading > .con-vs-loading')
      }, 500)
    },
    showLoading(container) {
      this.$vs.loading({
        color: 'primary',
        container,
        scale: 0.6
      })
    },
    closeLoading(container) {
      this.$vs.loading.close(container)
    },
    blur(filter) {
      const relatedToStates = ['postcode', 'suburb']

      if (relatedToStates.includes(filter)) {
        let params = {
          search: '',
          suburb: true,
          prereq_value: this.selectedData.postcode,
          prereq_value_2: this.selectedData.state
        }

        if (filter === 'postcode') {
          params = {
            search: '',
            postCode: true,
            prereq_value: this.selectedData.state
          }
        }

        this.fetchFilterByState({
          type: 'search',
          params
        })
      } else {
        const params = {
          type: filter,
          search: '',
        }

        if (filter === 'clinics') {
          params['states'] = this.selectedData.state
        }

        this.fetchAnalyticsFiltersSearch(params)
      }
    }
  },
  watch: {
    selected: {
      handler(newVal) {
        this.selectedData = newVal;
      },
      immediate: true,
    },
    selectedData: {
      handler(newVal) {
        this.$emit("updateFilters", newVal);
      },
      deep: true,
    },
    'selectedData.state': {
      handler() {
        if (this.triggerFilter === '' || this.triggerFilter === 'state') {
          const params = {
            state_abbreviation: this.selectedData.state,
          }
          const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
          if (org_id) params['org_id'] = org_id

          this.fetchAnalyticsFiltersSearchClinic(params)
        }

        if (this.selectedData.state && this.selectedData.state.length) {
          this.postcode.isLoading = true
          this.suburb.isLoading = true

          if (this.triggerFilter === '') {
            this.triggerFilter = 'state'
          }

          this.fetchFilterByState({
            type: 'both',
            params: {
              state: this.selectedData.state
            }
          }).then(() => {
            this.postcode.isLoading = false
            this.suburb.isLoading = false
          }).catch(() => {
            this.postcode.isLoading = false
            this.suburb.isLoading = false
          })
        } else {
          if (this.triggerFilter === 'state') {
            this.triggerFilter = ''
          }

          this.MUTATE_FILTERS({
            data: {
              from: 'reset',
              postCode: [],
              suburb: []
            }
          })
          this.selectedData.postcode = []
          this.selectedData.suburb = []
        }
      },
      deep: true
    },
    'selectedData.postcode': {
      handler() {
        if (this.selectedData.postcode && this.selectedData.postcode.length) {
          this.suburb.isLoading = true

          this.fetchFilterByState({
            type: 'postCode',
            params: {
              postCode: this.selectedData.postcode
            }
          }).then(() => this.suburb.isLoading = false
          ).catch(() => this.suburb.isLoading = false)
        } else {
          this.MUTATE_FILTERS({
            data: {
              from: 'reset',
              suburb: []
            }
          })
          this.selectedData.suburb = []
        }
      },
      deep: true
    },
    'selectedData.clinic': {
      async handler() {
        if (this.triggerFilter === '' || this.triggerFilter === 'clinic') {
          const result = await this.fetchClinicState({
            ids: this.selectedData.clinic,
          })

          if (result.data) {
            const { data } = result.data.clinics
            let states = compact(this.DEFAULT_STATE_FILTER.map((state) => {
              const matched = data.find((currState) => currState.state === state.text || currState.state === state.value)
              if (matched) return state
              return undefined
            }))

            if (!data.length) {
              states = this.DEFAULT_STATE_FILTER
            }

            this.MUTATE_FILTERS({
              data: {
                states
              }
            })
          }
        }

        if (this.selectedData.clinic && this.selectedData.clinic.length) {
          if (this.triggerFilter === '') {
            this.triggerFilter = 'clinic'
          }
          return
        }

        if (this.triggerFilter === 'clinic') {
          this.triggerFilter = ''
        }
      },
      deep: true
    },
  }
}
</script>

<style lang="css">
.filter-container>.con-select {
  width: 120px;
}

.con-select .vs-select--input,
.vs-select--options span {
  font-size: .85rem;
}

.removeSelectedFilters{
  position: absolute;
  margin-right: 18px;
  margin-top: 16px;
  cursor: pointer;
}
</style>

