<template>
  <div>
    <vs-row vs-w="12">
      <GlobalFilters
        :categories="categories"
        :subCategories="subCategories"
        :selectedCategory="selectedCategory"
        :selectedSubCategory="selectedSubCategory"
        @updateSelectedCategory="selectedCategory = $event"
        @updateSelectedSubCategory="selectedSubCategory = $event"
        :selected="selected"
        :page="`treatments-by-week-${type}`"
        @updateFilters="updateFilters"
        :urlType="urlType"
        :productType="type"
      />

      <div class="flex flex-col md:flex-row items-center justify-end space-y-4 md:space-y-0 w-full my-4">
        <p v-if="allTotalCount" class="optionslabel w-full md:w-1/2 text-center md:text-right">Total count of treatments: {{ Number.parseFloat(allTotalCount).toFixed(0) }}</p>
      </div>
      
      <BarChart :chartData="chartData" :chartOptions="chartOptions" />

    </vs-row>
  </div>
</template>
  
  <script>
import BarChart from "../charts/BarChart.vue";
import Filters from "../common/Filters.vue";
import GlobalFilters from "../common/GlobalFilters";
import { filterCategories } from "../common/GlobalFilters/filterConstant.js";

export default {
  components: {
    BarChart,
    Filters,
    GlobalFilters,
  },
  props: {
    type: {
      type: String,
    },
    selected: {
      type: Object,
    },
    chartDataProps: {
      type: Object,
    },
    isAllClinicsProps: {
      type: Boolean,
    },
    isDosage: {
      type: Boolean,
    },
  },
  computed: {
    allTotalCount() {
      const chartDataSets = this.chartData.datasets
        ? this.chartData.datasets[0]
        : null;
      let count = 0;
      if (chartDataSets && chartDataSets.data) {
        chartDataSets.data.forEach((item) => {
          count += item;
        });
      }
      return count;
    },
  },
  data() {
    return {
      categories: [
        filterCategories.ORGANIZATIONS,
        filterCategories.CLINICS,
        filterCategories.NURSE,
        filterCategories.PATIENTS,
        filterCategories.TREATMENTS,
      ],
      selectedCategory: {},
      subCategories: [],
      selectedSubCategory: {},
      isAllClinics: false,
      chartData: {},
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: "top",
            labels: {
              boxWidth: 12,
            },
          },
          title: {
            display: true,
            text: "",
          },
          tooltip: {
            callbacks: {
              footer: function (context) {
                const contextItem = context[0];
                const totalCount = contextItem.dataset.totalCount;
                let totalCountLabel = "";

                if (totalCount && totalCount.length) {
                  totalCountLabel =
                    "Count: " + totalCount[contextItem.dataIndex];
                }
                return totalCountLabel;
              },
            },
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: "NUMBER OF TREATMENTS",
            },
            min: 0,
          },
          x: {
            title: {
              display: true,
              text: "WEEK",
            },
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 10,
              },
              maxRotation: 90,
              minRotation: 90,
            },
          },
        },
      },
      urlType: "",
    };
  },  
  methods: {
    updateFilters(newVal) {
      this.$emit("updateFilters", newVal);
    },
  },
  watch: {
    chartDataProps: {
      handler(newVal) {
        this.chartData = newVal;
      },
      immediate: true,
    },
    chartData: {
      handler(newVal) {
        this.$emit("updateChartData", newVal);
      },
      deep: true,
    },
    isAllClinicsProps: {
      handler(newVal) {
        this.isAllClinics = newVal;
      },
      immediate: true,
    },
    isAllClinics: {
      handler(newVal) {
        this.$emit("updateIsAllClinics", newVal);
      },
    },
  },
  created() {
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if (userType && userType.userType === "superAdmin") {
      this.urlType = "SuperAdmin";
    } else if (
      userType &&
      userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()
    ) {
      this.urlType = "Supplier";
    } else {
      this.urlType = "OrgOwner";
    }
    if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.orgId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    if (userType && userType.brands.length) {
      this.supplierCurrentBrands = userType.brands;
    }
  },
};
</script>