<template>
  <div class="flex flex-col gap-2">
    <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
      <TabInsights
        :title="`Average Treatment By Area (${treatmentType})`"
        toolTiptext="What can I learn from this chart?"
        :hasQuickInsights="false"
      >
        <AverageTreatmentByArea
          :type="treatmentType"
          :selected="selected"
          :chartDataProps="chartData"
          @updateChartData="chartData = $event"
          :isAllClinicsProps="isAllClinics"
          @updateIsAllClinics="isAllClinics = $event"
          @updateFilters="selected = $event"
        />
      </TabInsights>
    </vs-row>

    <!-- <div class="flex justify-end mt-2 mb-2 w-full">
      <vs-button class="w-full lg:w-auto" @click="handleExportToCSV">
        Export
      </vs-button>
    </div> -->

    <vs-table
      v-if="chartData && chartData.labels"
      max-items="10"
      pagination
      noDataText="No Data Available"
      :data="chartData.labels"
      :key="'main-' + tableKey"
    >
      <template slot="thead">
        <vs-th
          style="
            background-color: rgba(235, 247, 244, 1);
            color: #5e5873;
            width: 40vw;
            max-width: 50vw;
          "
          >{{
            isArea ? "AREA" : isAge ? "AGE" : isNurse ? "NURSE" : "CLINIC"
          }}</vs-th
        >
        <vs-th style="background-color: rgba(235, 247, 244, 1); color: #5e5873"
          >AVERAGE UNIT PER AREA</vs-th
        >
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr">
            {{ tr }}
          </vs-td>
          <vs-td :data="tr">
            {{ getTotalTreatments(tr).toFixed(2) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AverageTreatmentByArea from "../../components/insights/treatments/AverageTreatmentByArea.vue";
import TabInsights from "../../components/insights/TabsInsights.vue";
import Sidebar from "./Sidebar.vue";
import utils from "@/assets/utils";
import moment from "moment";
import helper from "./helper";

export default {
  components: {
    AverageTreatmentByArea,
    TabInsights,
    Sidebar,
  },
  props: {
    isArea: Boolean,
    isAge: Boolean,
    isNurse: Boolean,
    isAllClinicsProps: Boolean,
  },
  data() {
    return {
      selected: {},
      chartData: {},
      treatmentType: "",
      urlType: "",
      orgId: "",
      isAllClinics: false,
      supplierCurrentBrands: [],
      tableKey: "",
    };
  },
  computed: {
    ...mapState("analytics", ["FILTERS_SELECTED"]),
  },
  methods: {
    ...mapActions("analytics", ["fetchTreatmentAreasArea"]),
    getTreatmentAreasArea() {
      this.$vs.loading();

      let payload = helper.generateAnalyticsAPIParameters(this.selected);
      payload.is_area = this.isArea;
      payload.is_age = this.isAge;
      payload.is_nurse = this.isNurse;
      payload.type = this.treatmentType;

      if (this.urlType === "Supplier" && !this.isAllClinics) {
        if (this.selected.brand.length) {
          payload.brand = this.selected.brand
        } else {
          payload.brand = this.supplierCurrentBrands
        }
      }
      if (!["SuperAdmin", "Supplier"].includes(this.urlType)) {
        payload.org = [this.orgId]
      }
      this.fetchTreatmentAreasArea(payload)
        .then((res) => {
          this.chartData = res.data.chartData;
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.error("#ERR: ", err.message);
          this.$vs.loading.close();
        });
    },
    getTotalTreatments(index) {
      let dataIndex = this.chartData.labels.findIndex((e) => e === index);
      let totalAmount = this.chartData.datasets.reduce((total, curr) => {
        total += curr.data[dataIndex];
        return total;
      }, 0);
      return totalAmount;
    },
    handleExportToCSV() {
      let csv = "\ufeff";
      let filename = `avg_dose(mL)_per_treatment_by_treatment_area(${this.treatmentType})_`;
      const csvHeaderFirstColumn = this.isArea ? "AREA" : this.isAge ? "AGE" : this.isNurse ? "NURSE" : "CLINIC"
      csv += csvHeaderFirstColumn + ",AVERAGE UNIT PER AREA\n";

      this.chartData.labels.forEach((row) => {
        csv += `${row},${this.getTotalTreatments(row).toFixed(2)}\n`;
      });

      utils.exportToCSV(csv, `${filename}${moment().format("YYMMDDHHmm")}`);
    },
  },
  watch: {
    $route: {
      handler(newVal) {
        if (
          [
            "SuperAdminAverageTreatmentByAreaFiller",
            "OrgOwnerAverageTreatmentByAreaFiller",
            "SupplierAverageTreatmentByAreaFiller",
          ].includes(newVal.name)
        ) {
          this.treatmentType = "filler";
        } else if (
          [
            "SuperAdminAverageTreatmentByAreaToxin",
            "OrgOwnerAverageTreatmentByAreaToxin",
            "SupplierAverageTreatmentByAreaToxin",
          ].includes(newVal.name)
        ) {
          this.treatmentType = "toxin";
        } else {
          this.treatmentType = "all";
        }
      },
      immediate: true,
    },
    selected: {
      handler() {
        this.getTreatmentAreasArea();
      },
      deep: true,
    },
    chartData: {
      handler() {
        const randNumber = Math.random().toString(16).slice(2);
        this.tableKey = randNumber;
      },
      deep: true,
    },
  },
  async created() {
    this.selected = _.cloneDeep(this.FILTERS_SELECTED);
    let userType = JSON.parse(
      localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
    );
    if (userType && userType.userType === "superAdmin") {
      this.urlType = "SuperAdmin";
    } else if (
      userType &&
      userType.organizations[0].role.toUpperCase() === "supplier".toUpperCase()
    ) {
      this.urlType = "Supplier";
    } else {
      this.urlType = "OrgOwner";
    }
    if (
      localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      )
    ) {
      this.orgId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
    }
    if (userType && userType.brands.length) {
      this.supplierCurrentBrands = userType.brands;
    }
  },
};
</script>
